var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"calls-create-dialog",attrs:{"visible":_vm.localVisible,"title":_vm.title},on:{"update:visible":function($event){_vm.localVisible=$event}}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"body-scroll-lock-ignore":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ТИП КОНТАКТА","rules":_vm.rules.role,"error-message":_vm.errors.role}},[_c('v-tabs',{staticClass:"calls-create-dialog__role-tabs",attrs:{"primary":""},model:{value:(_vm.call.role),callback:function ($$v) {_vm.$set(_vm.call, "role", $$v)},expression:"call.role"}},_vm._l((_vm.$options.CALL_ROLE_MAP),function(role,index){return _c('v-tab',{key:index,attrs:{"disabled":!_vm.isCreation,"name":role.value}},[_vm._v(" "+_vm._s(role.label)+" ")])}),1)],1)],1),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ТЕЛЕФОН КЛИЕНТА","rules":_vm.rules.phone,"error-message":_vm.errors.phone},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input-phone',{attrs:{"is-error":!!validationErrors.length},scopedSlots:_vm._u([(_vm.successAppend)?{key:"append",fn:function(){return [(_vm.successAppend === 'success')?_c('v-success',{staticClass:"calls-create-dialog__success-icon"}):_c('v-not-success')]},proxy:true}:null],null,true),model:{value:(_vm.call.phone),callback:function ($$v) {_vm.$set(_vm.call, "phone", $$v)},expression:"call.phone"}})]}},{key:"error",fn:function(ref){
var errorMessage = ref.errorMessage;
return [_c('v-form-user-error',{attrs:{"error-message":errorMessage,"user":_vm.phoneSearchUser,"call-role":_vm.call.role}})]}}],null,true)})],1),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ИМЯ КЛИЕНТА","rules":_vm.rules.name,"error-message":_vm.errors.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length,"type":"text"},model:{value:(_vm.call.name),callback:function ($$v) {_vm.$set(_vm.call, "name", $$v)},expression:"call.name"}})]}}],null,true)})],1),(_vm.isRoleAdmin)?_c('v-form-row',[_c('v-form-field',{attrs:{"label":"АГЕНТ","rules":_vm.rules.agent,"error-message":_vm.errors.agent},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-select',{attrs:{"label":"name","append-to-body":"","is-error":!!validationErrors.length,"options":_vm.agentOptions,"loading":_vm.agentOptionsLoading},model:{value:(_vm.call.agent),callback:function ($$v) {_vm.$set(_vm.call, "agent", $$v)},expression:"call.agent"}})]}}],null,true)})],1):_vm._e(),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ИСТОЧНИК ЛИДА","rules":_vm.rules.callSource,"error-message":_vm.errors.callSource},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-select',{attrs:{"label":"name","append-to-body":"","is-error":!!validationErrors.length,"options":_vm.callSourceOptions},model:{value:(_vm.call.callSource),callback:function ($$v) {_vm.$set(_vm.call, "callSource", $$v)},expression:"call.callSource"}})]}}],null,true)})],1),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ДАТА","rules":_vm.rules.callDate,"error-message":_vm.errors.callDate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input-date',{staticClass:"calls-create-dialog__date-input",attrs:{"clearable":false,"format":"DD.MM.YYYY","is-disabled-dates-after-current-date":"","value-type":"format","is-error":!!validationErrors.length},model:{value:(_vm.call.callDate),callback:function ($$v) {_vm.$set(_vm.call, "callDate", $$v)},expression:"call.callDate"}})]}}],null,true)})],1),(_vm.call.role === _vm.$options.CALL_ROLES.CUSTOMER)?_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ОБЪЕКТ НЕДВИЖИМОСТИ","rules":_vm.rules.advert,"separate-label":"","hide-errors":"","error-message":_vm.errors.advert},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-select',{attrs:{"append-to-body":"","label":"name","is-error":!!validationErrors.length,"options":_vm.advertOptions},on:{"search":_vm.onAdvertSearch},model:{value:(_vm.call.advert),callback:function ($$v) {_vm.$set(_vm.call, "advert", $$v)},expression:"call.advert"}})]}}],null,true)})],1):_vm._e(),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"КОММЕНТАРИЙ","rules":_vm.rules.text,"error-message":_vm.errors.text},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-textarea',{attrs:{"is-error":!!validationErrors.length,"rows":3},model:{value:(_vm.call.text),callback:function ($$v) {_vm.$set(_vm.call, "text", $$v)},expression:"call.text"}})]}}],null,true)})],1)]},proxy:true},{key:"footer",fn:function(){return [_c('v-button',{attrs:{"disabled":!_vm.isCreation && _vm.isFieldsNotChanged,"primary":"","type":"submit"}},[_vm._v("Сохранить")])]},proxy:true}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }