var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',_vm._g({class:[
    'v-radio',
    {
      'v-radio--focused': _vm.focus,
      'v-radio--disabled': _vm.disabled,
      'v-radio--active': _vm.isActive
    }
  ],attrs:{"role":"radio","aria-checked":_vm.isActive,"aria-disabled":_vm.disabled,"tabindex":_vm.disabled ? -1 : 0},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.model = _vm.value}}},_vm.inheritListeners),[_c('span',{staticClass:"v-radio__body"},[_vm._t("default")],2),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],staticClass:"v-radio__original-radio",attrs:{"type":"radio","tabindex":-1,"disabled":_vm.disabled},domProps:{"value":_vm.value,"checked":_vm._q(_vm.model,_vm.value)},on:{"focus":function($event){_vm.focus = true},"blur":function($event){_vm.focus = false},"change":function($event){_vm.model=_vm.value}}})])}
var staticRenderFns = []

export { render, staticRenderFns }