<template>
  <match-media v-slot="{ desktop, tablet, mobile }">
    <aside class="calls__filters-mobile">
      <v-drawer :visible.sync="filtersVisible">
        <filters-mobile
          :default-filters="queryParams"
          :count="params.count"
          @close="filtersVisible = false"
          @apply-filters="applyFilters"
        />
      </v-drawer>
    </aside>

    <aside v-if="!mobile" class="calls__bulk-edit">
      <v-drawer position="right" without-background :visible.sync="bulkEditVisible">
        <calls-bulk-edit-dialog
          :calls="selectedCallsEdit"
          @close="bulkEditVisible = false"
          @delete="deleteCalls"
          @submitted="editCallsSubmit"
        ></calls-bulk-edit-dialog>
      </v-drawer>
    </aside>

    <div class="calls">
      <div ref="callsTopWrapper" class="calls__top-wrapper">
        <div class="calls__header">
          <v-page-title class="calls__title">
            Мои лиды
            <span class="calls__count">{{ params.count }}</span>
          </v-page-title>
          <v-suggest-search
            v-if="desktop"
            class="calls__searchrow"
            :min-number-length="$options.MINIMUM_SEARCH_LENGTH_BY_NUMBERS"
            :min-string-length="$options.MINIMUM_SEARCH_LENGTH_BY_STRING"
            :query-string="queryParams.phone || queryParams.query"
            :get-suggestions-method="getSearchSuggestionsList"
            @reset="resetSuggestions"
          >
            <template #searchResultItem="{item}">
              <customer-suggest-search-item
                :customer="item"
                @click="goToCallerPage(item)"
              ></customer-suggest-search-item>
            </template>
          </v-suggest-search>
          <div class="calls__buttons">
            <v-button v-if="!mobile" class="calls__button calls__button--filters" @click="toggleFilters">
              <v-icon-filters class="calls__button-icon calls__button-icon--filters" />
              {{ desktop ? '' : 'Фильтры' }}
            </v-button>
            <v-button v-if="isRoleAdmin" primary class="calls__button calls__button--add" @click="addCall">
              <v-icon-plus class="calls__icon-plus" />
              Добавить лид
            </v-button>
          </div>
        </div>

        <v-button v-if="mobile" class="calls__button calls__button--filters" @click="toggleFilters">
          <v-icon-filters class="calls__button-icon calls__button-icon--filters" />
          Фильтры
        </v-button>

        <v-suggest-search
          v-if="!desktop"
          class="calls__search"
          :min-number-length="$options.MINIMUM_SEARCH_LENGTH_BY_NUMBERS"
          :min-string-length="$options.MINIMUM_SEARCH_LENGTH_BY_STRING"
          :query-string="queryParams.phone || queryParams.query"
          :get-suggestions-method="getSearchSuggestionsList"
          @reset="resetSuggestions"
        >
          <template #searchResultItem="{item}">
            <customer-suggest-search-item :customer="item" @click="goToCallerPage(item)"></customer-suggest-search-item>
          </template>
        </v-suggest-search>
      </div>
      <div class="calls__content">
        <template v-if="!mobile">
          <calls-table
            :table-height="tableHeight"
            :infinite-loading="infiniteLoading"
            :list="list"
            :infinite-id="infiniteId"
            @edit="editCall"
            @next-page-request="nextPageRequest"
            @change-sort="applyFilters"
            @bulk-edit="bulkEdit"
          ></calls-table>
        </template>
        <template v-else>
          <calls-list-item
            v-for="call in list"
            :key="call.id"
            v-bind="call"
            @delete="deleteCalls([call.id])"
            @pending-edit="editCall(call)"
            @edit="editCallMobile(call)"
          />
          <infinite-loading force-use-infinite-wrapper :identifier="infiniteId" @infinite="infiniteHandler">
            <template #no-more><span></span></template>
            <template #no-results><span></span></template>
          </infinite-loading>
        </template>
      </div>
      <calls-create-dialog :visible.sync="editDialogVisible" :call-data="callData" @submitted="editCallsSubmit" />
      <call-edit-pending-dialog
        :visible.sync="editPendingDialogVisible"
        :draft-data="callDraftData"
        :call-data="callData"
        @submitted="editCallsSubmit"
      ></call-edit-pending-dialog>
    </div>
  </match-media>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import VIconFilters from '@/components/icons/VFilters.vue'
import VIconPlus from '@/components/icons/VPlus.vue'
import FiltersMobile from '@/components/Calls/Filters/Mobile.vue'
import CustomerSuggestSearchItem from '@/components/Customer/SuggestSearchItem.vue'
import VPageTitle from '@/components/common/VPageTitle.vue'
import VButton from '@/components/common/VButton.vue'
import VDrawer from '@/components/common/VDrawer.vue'
import VSuggestSearch from '@/components/common/VSuggestSearch.vue'
import noticeService from '@/services/notification'
import confirmService from '@/services/confirmation'
import { agentsService, ownersService, customersService, authService } from '@/services/http'
import CancelSourceManager from '@/services/http/CancelSourceManager'
import { loadingService } from '@/services/loading'
import redirectIfNetworkIssue from '@/router/utils'
import { MatchMedia } from 'vue-component-media-queries'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { pluralizeNumeral } from '@/utils/pluralization'
import CallsTable from '@/components/Calls/Table.vue'
import CallsListItem from '@/components/Calls/ListItem.vue'
import { getCalculatingHeightTable } from '@/utils/calculatingHeightTable'
import CallsCreateDialog from '@/components/Calls/CreateDialog.vue'
import CallsBulkEditDialog from '@/components/Calls/BulkEditDialog.vue'
import CallEditPendingDialog from '@/components/Calls/EditPendingDialog.vue'
import { CALL_ROLES } from '@/constants/callsRoles'

const MINIMUM_SEARCH_LENGTH_BY_NUMBERS = 7
const MINIMUM_SEARCH_LENGTH_BY_STRING = 3
const CALLER_ROLES = {
  OWNER: 'owner',
  CUSTOMER: 'customer'
}

export default {
  MINIMUM_SEARCH_LENGTH_BY_NUMBERS,
  MINIMUM_SEARCH_LENGTH_BY_STRING,
  name: 'Calls',
  components: {
    CustomerSuggestSearchItem,
    CallsListItem,
    CallsTable,
    CallsCreateDialog,
    CallsBulkEditDialog,
    CallEditPendingDialog,
    InfiniteLoading,
    VSuggestSearch,
    VDrawer,
    VIconFilters,
    FiltersMobile,
    MatchMedia,
    VPageTitle,
    VIconPlus,
    VButton
  },
  data() {
    return {
      // eslint-disable-next-line vue/max-len
      infiniteId: +new Date(), // Рекомендованное значение по умолчанию +new Date() https://peachscript.github.io/vue-infinite-loading/api/#identifier
      infiniteLoading: false,
      tableHeight: 0,
      filtersVisible: false,
      loading: false,
      list: [],
      callData: {},
      callDraftData: {},
      ownerCallSources: [],
      customerCallSources: [],
      params: {
        count: 0,
        offset: 0,
        limit: 10
      },
      editDialogVisible: false,
      editPendingDialogVisible: false,
      bulkEditVisible: false,
      selectedCallsEdit: []
    }
  },
  computed: {
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    }),
    queryParams() {
      return {
        phone: this.$route.query.phone || undefined,
        query: this.$route.query.query || undefined,
        categoryId: Number(this.$route.query.categoryId) || undefined,
        agentId: Number(this.$route.query.agentId) || undefined,
        callSourceId: Number(this.$route.query.callSourceId) || undefined,
        priceLte: Number(this.$route.query.priceLte) || undefined,
        priceGte: Number(this.$route.query.priceGte) || undefined,
        dateTo: this.$route.query.dateTo || undefined,
        dateFrom: this.$route.query.dateFrom || undefined,
        callType: this.$route.query.callType || undefined,
        orderBy: this.$route.query.orderBy || '-call_date'
      }
    }
  },
  watch: {
    loading(val) {
      loadingService.setViewLoading(val)
    },
    editPendingDialogVisible(val) {
      if (!val) {
        this.callData = {}
        this.callDraftData = {}
      }
    },
    editDialogVisible(val) {
      if (!val) this.callData = {}
    }
  },
  created() {
    const OwnerCallSourcesRequest = this.fetchOwnerCallSources()
    const CustomerCallSourcesRequest = this.fetchCustomerCallSources()
    Promise.all([OwnerCallSourcesRequest, CustomerCallSourcesRequest]).then(() => {
      this.fetchCalls()
    })
  },
  mounted() {
    const { callsTopWrapper } = this.$refs
    this.tableHeight = getCalculatingHeightTable(callsTopWrapper)
  },
  beforeRouteUpdate(to, from, next) {
    this.$nextTick(() => {
      this.resetCallsList()
      this.fetchCalls()
    })
    next()
  },
  methods: {
    bulkEdit(calls) {
      if (calls.length) {
        this.selectedCallsEdit = calls.map(call => this.formattingEditCallData(call))
        this.bulkEditVisible = true
      } else {
        this.selectedCallsEdit = []
        this.bulkEditVisible = false
      }
    },
    infiniteHandler($state) {
      this.nextPageRequest($state)
    },
    resetCallsList() {
      this.callData = {}
      this.callDraftData = {}
      this.params.offset = 0
      this.list = []
    },
    nextPageRequest(infinityState) {
      if (this.list.length) this.fetchCalls({ infinityState })
    },
    formatCallsList({ list }) {
      return list.map(call => {
        return {
          ...call,
          callSource: this.getCallSource(call)
        }
      })
    },
    resetSuggestions() {
      this.updateQueryParams({ phone: undefined, query: undefined })
    },
    updateQueryParams(newParams) {
      this.$router.push({ query: { ...this.queryParams, ...newParams } })
    },
    toggleFilters() {
      this.filtersVisible = !this.filtersVisible
    },
    filtersSearch(filters) {
      this.updateQueryParams(filters)
    },
    applyFilters(filters) {
      this.filtersVisible = false
      this.updateQueryParams(filters)
    },
    addCall() {
      this.editDialogVisible = true
    },
    editCallMobile(call) {
      this.editDialogVisible = true
      this.callData = this.formattingEditCallData(call)
    },
    editCall(call) {
      this.callData = this.formattingEditCallData(call)
      this.fetchCallDraft(call.versionId).then(() => {
        this.editPendingDialogVisible = true
      })
    },
    fetchCallDraft(versionId) {
      return agentsService.getCallDraft(versionId).then(draftData => {
        this.callDraftData = this.formattingEditCallData(draftData)
      })
    },
    formattingEditCallData(data) {
      return {
        ...data,
        callSource: this.getCallSource(data)
      }
    },
    getCallSource({ account: { role }, callSource }) {
      const callSourcesListByRole = role === CALLER_ROLES.CUSTOMER ? this.customerCallSources : this.ownerCallSources
      if (callSourcesListByRole.length) {
        if (callSource) {
          const source = callSourcesListByRole.find(
            selectorCallSource => selectorCallSource.id === (callSource.id || callSource)
          )
          if (source) return source
        }
      }
      return callSourcesListByRole.find(callSourceValue => callSourceValue.name.toLowerCase() === 'не выбран')
    },
    fetchOwnerCallSources() {
      return ownersService.getCallSourcesMap().then(callSources => {
        this.ownerCallSources = callSources
      })
    },
    fetchCustomerCallSources() {
      return customersService.getCallSourcesMap().then(callSources => {
        this.customerCallSources = callSources
      })
    },
    getClientLinkByRole(role, id) {
      const ROLE_LINKS_MAP = {
        [CALL_ROLES.CUSTOMER]: { name: 'customer-edit', params: { customerId: id } },
        [CALL_ROLES.OWNER]: { name: 'owner-edit', params: { ownerId: id } }
      }
      return ROLE_LINKS_MAP[role]
    },
    goToCallerPage({ id, role }) {
      this.$router.push(this.getClientLinkByRole(role, id))
    },
    getSearchSuggestionsList({ inputValue, fetchType }) {
      this.updateQueryParams(
        fetchType === 'number' ? { phone: inputValue, query: undefined } : { phone: undefined, query: inputValue }
      )

      const runWithCancel = CancelSourceManager.withCancelSource('fetch-clients-suggest-search', true)
      return runWithCancel(({ cancelSource }) => {
        const searchParam = fetchType === 'number' ? { phone: inputValue } : { name: inputValue }
        return authService.select({
          ...searchParam,
          ...{ cancelToken: cancelSource.token, role: ['owner', 'customer'] }
        })
      }).then(({ results }) => {
        return results
      })
    },
    fetchCalls({ infinityState, ...params } = {}) {
      const runWithCancel = CancelSourceManager.withCancelSource('fetch-calls', true)

      const fetchParams = { ...this.queryParams, ...params }
      const { limit, offset } = this.params

      runWithCancel(({ cancelSource }) => {
        if (!infinityState) this.loading = true
        else this.infiniteLoading = true
        return agentsService.getCallsList({ limit, offset, cancelToken: cancelSource.token, ...fetchParams })
      })
        .then(({ count, results }) => {
          this.params = { ...this.params, count }
          if (results.length) {
            this.list.push(...this.formatCallsList({ list: results }))
            this.params.offset += this.params.limit
            if (infinityState) {
              infinityState.loaded()
            } else {
              this.infiniteId += 1 // сброс скролла на текущее значение
            }
            return
          }
          if (infinityState) infinityState.complete()
        })
        .catch(redirectIfNetworkIssue)
        .finally(() => {
          this.loading = false
          this.infiniteLoading = false
        })
    },
    editCallsSubmit() {
      this.resetCallsList()
      this.fetchCalls()
    },
    deleteCalls(ids) {
      const callsGenitiveCase = pluralizeNumeral(ids.length, 'лид', 'лиды', 'лиды')
      const successText = pluralizeNumeral(ids.length, 'Лид удалён', 'Лиды удалены', 'Лиды удалены')

      confirmService
        .ask({ title: `Вы точно хотите удалить ${callsGenitiveCase}?` })
        .then(() => {
          this.loading = true
          agentsService
            .bulkDeleteCalls(ids)
            .then(() => {
              this.resetCallsList()
              this.fetchCalls()
              noticeService.success(successText)
            })
            .catch(() => noticeService.error(`Не удалось удалить ${callsGenitiveCase}`))
            .finally(() => {
              this.loading = false
            })
        })
        .catch(() => {})
    }
  }
}
</script>
